import {
  addBookingsPagesAsPanel,
  createBookCheckoutState,
  createCalendarPageState,
  createServicePageState,
} from './pages-panel-actions';

export async function handlePagesPanelMigration(sdk, appToken, allSitePages) {
  await createBookCheckoutState(sdk, appToken, allSitePages);
  await createServicePageState(sdk, appToken, allSitePages);
  await createCalendarPageState(sdk, appToken, allSitePages);
  await addBookingsPagesAsPanel(sdk, appToken);
}

export function shouldProposeMigration(editorSdk, isAdi, appToken, instance) {
  const isFromTemplate = !JSON.parse(atob(instance.split('.')[1])).metaSiteId;
  return !isAdi && !isFromTemplate;
}
